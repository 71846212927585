// CSS
import '../../sass/components/sticky-booking-search-form.scss'

document.addEventListener('alpha.loaded', (e) => {
  const alphaModuleMgr = e.detail.emitter
  const alphaConfig = alphaModuleMgr.getConfig()

  alphaModuleMgr.addModuleEventListener('viewport', 'changed', (evt) => {
    const defaultStickyHeight = 90
    const viewport = evt.viewport
    const formEl = document.querySelector('.booking-search.form')
    const offcanvasBodyEl = document.querySelector('.nav-main .offcanvas-body')
    const footerEl = document.querySelector('footer')
    let resaHeight = Number(document.querySelector('.booking-search-form-wrapper').offsetHeight)
    if (!resaHeight) {
      resaHeight = defaultStickyHeight
    }

    resaHeight = `${resaHeight}px`

    if (formEl) {
      const myObserver = new IntersectionObserver((entries) => {
        entries.forEach((entry) => handleStickForm(formEl, alphaConfig, !entry.isIntersecting))
      })

      myObserver.observe(document.querySelector('.booking-search-form-wrapper'))
    }

    function handleStickForm(formEl, alphaConfig, sticky) {
      const mainBookingWrapperEl = document.querySelector('.booking-search-form-wrapper')
      const stickyFormWrapperEl = getSearchStickyFormWrapperElement(alphaConfig)
      if (stickyFormWrapperEl) {
        stickyFormWrapperEl.classList.toggle('sticky-visible', sticky)
        if (sticky) {
          const style = `margin-bottom: ${resaHeight}`
          stickyFormWrapperEl.appendChild(formEl)
          footerEl.style.marginBottom = resaHeight

          if (viewport.is(`<=${alphaConfig.desktopBreakpoint}`)) {
            offcanvasBodyEl.setAttribute('style', style)
          }
        } else {
          if (mainBookingWrapperEl) {
            mainBookingWrapperEl.appendChild(formEl)
          }

          footerEl.style.marginBottom = ''
          offcanvasBodyEl.removeAttribute('style')
        }

        // handle mobile display controls
        const mobileControls = stickyFormWrapperEl.querySelectorAll('.sticky-mobile-control')
        mobileControls.forEach((stickyMobileControlEl) => {
          if (!stickyMobileControlEl.classList.contains('control-initialize')) {
            stickyMobileControlEl.classList.add('control-initialize')
            stickyMobileControlEl.addEventListener('click', (e) => {
              e.preventDefault()

              stickyFormWrapperEl.classList.toggle('sticky-open')
            })
          }
        })

        const travelersInputEl = document.querySelector('input[name="travelers"]')
        if (travelersInputEl) {
          if (viewport.is(`<=${alphaConfig.desktopBreakpoint}`)) {
            travelersInputEl.removeAttribute('readonly')
          } else {
            travelersInputEl.setAttribute('readonly', 'readonly')
          }
        }
      }
    }

    function getSearchStickyFormWrapperElement(alphaConfig) {
      let wrapperEl = document.querySelector('.booking-search-sticky-wrapper')
      if (!wrapperEl) {
        wrapperEl = _buildSearchStickyFormWrapperElement(alphaConfig)
        document.body.appendChild(wrapperEl)
      }

      return wrapperEl
    }

    function _buildSearchStickyFormWrapperElement(alphaConfig) {
      const wrapperEl = document.createElement('div')
      wrapperEl.classList.add('booking-search-sticky-wrapper', 'booking-search-form-wrapper', 'fixed-bottom', 'shadow', `d-${alphaConfig.desktopBreakpoint}-flex`, 'justify-content-center', 'start-0', 'end-0', `py-${alphaConfig.desktopBreakpoint}-4`)
      wrapperEl.innerHTML = alphaConfig.bookingStickyWrapperHtml

      return wrapperEl
    }
  })
})
